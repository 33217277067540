body {
  font-family: var(--font-family);
}                                        

.ddtoggihead h5 {
  align-items: center;
  display: flex;
}
.ddtoggihead h5 label {
  margin-left: auto;
}


.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: rgba(0, 0, 0, 0.02) !important;
}